import ActionButton from '../../shared/ActionButton';
import LegoLogo from '../../shared/LegoLogo';
import PressTheButton from '../../assets/i/press-the-button.png';

export default function Action({
  onSelect,
}) {
  return (
    <div className="tutorial-jump-wrapper h-full flex flex-col items-center">
      <LegoLogo />

      <ActionButton onClick={() => onSelect()} className="my-4 mt-8" />

      <img src={PressTheButton} alt="Press the button" className="max-w-[13rem] relative z-20" />
    </div>
  )
}