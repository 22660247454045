import ActionOnScreenImg from '../../assets/i/action-on-main.png'

export default function ActionOnScreen({
  children,
}) {
  return (
    <div className="flex justify-center pt-16">
      <img src={ActionOnScreenImg} alt="Action on the main screen"
        className="max-w-[80%]" />
    </div>
  )
}
