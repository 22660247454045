export default function ActionButton({
  children,
  ...props
}) {
  return (
    <button {...props} className={`button-action ${props.className}`}>
      <div>
        <div>
          {children}
        </div>
      </div>
    </button>
  )
}
