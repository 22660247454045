import Player1Img from '../../assets/i/message/player-1.png'
import Player2Img from '../../assets/i/message/player-2.png'
import Player3Img from '../../assets/i/message/player-3.png'

export default function Message({
  children,
}) {
  return (
    <div className="flex justify-center ml-10 mr-5">
      <div className="relative w-full max-w-sm">
          <img src={Player1Img} className="absolute z-10 w-36 top-1 left-[-28px]
            active:left-[-33px] active:top-0 active:rotate-[-2deg] origin-right transition-all" alt="Player 1" />
          <img src={Player2Img} className="absolute z-10 w-36 top-1 right-[-25px]
            active:right-[-30px] active:top-0 active:rotate-2 origin-left transition-all" alt="Player 2" />
          <img src={Player3Img} className="absolute z-30 w-36 bottom-[-20px] left-[-15px]
            active:rotate-2 origin-right transition-all" alt="Player 3" />
        <div className="message-bubble relative z-20">
          {children}
        </div>
      </div>
    </div>
  )
}
