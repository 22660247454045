export default function Button({
  children,
  className = '',
  type = 'button',
  ...props
}) {
  return (
    <button type={type} className={`relative z-20 button-primary text-xl ${className}`} {...props}>
      <span>{children}</span>
    </button>
  )
}
