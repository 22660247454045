function gameStateReducer(state, action) {
  switch (action.scene) {
    case 'highscores':
      return {
        view: action.type === 'final' ? 'thankYou' : 'enterHighscore',
      }
    case 'settings':
    case 'tutorial':
    case 'setupSetSpeed':
    case 'setupSwipe':
    case 'action':
      return {
        view: action.scene,
      }
    default:
      return {
        view: 'actionOnScreen',
      }
  }
}

export default gameStateReducer;