import React, { useCallback, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useSocket, useSocketEvent } from '../../hooks/useSocketIO';
import Settings from '../Settings';
import Tutorial from '../Tutorial';
import SetupSetSpeed from '../SetupSetSpeed';
import SetupSwipe from '../SetupSwipe';
import Action from '../Action';
import EnterHighscore from '../EnterHighscore';
import gameStateReducer from './gameStateReducer';
import Message from '../../shared/Message';
import ActionOnScreen from '../../shared/ActionOnScreen';
import LegoLogo from '../../shared/LegoLogo';

export function MessageWithLogo({
  children,
}) {
  return (
    <React.Fragment>
      <LegoLogo />
      <Message>{children}</Message>
    </React.Fragment>
  )
}

export default function Player() {
  const { kioskId, code } = useParams();

  const socketOptions = {
    auth: {
      clientType: 'user',
      code,
      kioskId,
    },
  };

  const { socket, connected: socketConnected, error: connectError } = useSocket(process.env.REACT_APP_WS_HOST, socketOptions);
  const { message: connectionError } = useSocketEvent(socket, 'connect-error');
  const { message: invalidCode = false } = useSocketEvent(socket, 'invalidCode');
  const { message: gameInProgress = false } = useSocketEvent(socket, 'gameInProgress');
  const { message: sessionExpired = false } = useSocketEvent(socket, 'sessionExpired');
  const { message: gameOver = false } = useSocketEvent(socket, 'gameOver');
  const { message: {
    scene = null,
    sceneState = {},
    userState = {},
  } = {} } = useSocketEvent(socket, 'state');
  const { emit: emitSetSettings } = useSocketEvent(socket, 'setSettings');
  const { emit: emitNextTutorial } = useSocketEvent(socket, 'nextTutorial');
  const { emit: emitSettingsSelection } = useSocketEvent(socket, 'settingsSelection');
  const { emit: emitSwipe } = useSocketEvent(socket, 'swipe');
  const { emit: emitSkipTutorial } = useSocketEvent(socket, 'skipTutorial');
  const { emit: emitSetupSetSpeed } = useSocketEvent(socket, 'setupSetSpeed');
  const { emit: emitSetupSwipe } = useSocketEvent(socket, 'setupSwipe');
  const { emit: emitAction } = useSocketEvent(socket, 'action');
  const { emit: emitHighscoreInput } = useSocketEvent(socket, 'highscoreInput');
  const { emit: emitSaveHighscore } = useSocketEvent(socket, 'saveHighscore');

  const [gameState, dispatch] = useReducer(gameStateReducer, {
    view: 'loading',
  });

  useEffect(() => {
    if (scene !== null) {
      dispatch({
        scene,
        type: sceneState.type,
      });
    }
  }, [scene, sceneState]);

  const onAction = useCallback(() => {
    emitAction();
    dispatch({
      type: 'waive',
    });
  }, [emitAction]);

  const onSaveHighscore = useCallback((name) => {
    emitSaveHighscore(name);
    window.trackGameCompletion(kioskId);
  }, [emitSaveHighscore, kioskId]);
  
  const onHighscoreInput = useCallback(() => {
    emitHighscoreInput();
  }, [emitHighscoreInput]);

  // analytics
  useEffect(() => {
    if (socketConnected) {
      window.trackQRScan(kioskId);
    }
  }, [socketConnected, kioskId]);

  if (connectError) {
    console.error(connectError);

    return <MessageWithLogo>Cannot connect to the server<br /><br />{connectError}</MessageWithLogo>
  }

  if (connectionError) {
    console.error('connectionError', connectionError);

    return <MessageWithLogo>⛔️ {connectionError}</MessageWithLogo>
  }

  if (invalidCode) {
    return <MessageWithLogo>Invalid link.<br />Please scan QR again. 🔐</MessageWithLogo>
  }

  if (gameInProgress) {
    return <MessageWithLogo>Game in progress. Join once the game is over</MessageWithLogo>
  }

  if (!socketConnected) {
    return <MessageWithLogo>
      Connecting...
    </MessageWithLogo>
  }

  if (sessionExpired) {
    return <MessageWithLogo>
      Timeout.<br />Scan QR to play again!
    </MessageWithLogo>
  }

  if (gameOver) {
    return (
      <MessageWithLogo>
        Thank you for playing!
      </MessageWithLogo>
    )
  }

  switch (gameState.view) {
    case 'settings':
      return <Settings
        type={sceneState.type}
        onSelect={(value) => emitSetSettings(value)}
        onSelection={(value) => emitSettingsSelection(value)}
      />
    case 'tutorial':
      return <Tutorial
        player={userState.player}
        type={sceneState.type}
        onSelect={() => emitNextTutorial()}
        onSkip={() => emitSkipTutorial()}
        onSwipe={() => emitSwipe()}
      />
    case 'setupSetSpeed':
      return <SetupSetSpeed
        onSet={() => emitSetupSetSpeed()}
      />
    case 'setupSwipe':
      return <SetupSwipe
        player={userState.player}
        onSwipe={() => emitSwipe()}
        onSwipesCompleted={() => emitSetupSwipe()}
      />
    case 'action':
      return <Action
        onSelect={onAction}
      />
    case 'enterHighscore':
      return <EnterHighscore
        onInput={() => onHighscoreInput()}
        onEnter={(name) => onSaveHighscore(name)}
      />
    case 'thankYou':
      return (
        <MessageWithLogo>
          Thank you for playing!
        </MessageWithLogo>
      )
    case 'actionOnScreen':
      return (
        <React.Fragment>
          <LegoLogo />
          <ActionOnScreen />
        </React.Fragment>
      )
    case 'loading':
    default:
      return <MessageWithLogo>Loading...</MessageWithLogo>
  }
}
