import { useState, useCallback, useEffect } from 'react';
import HighscoreInputs from '../../shared/HighscoreInputs';
import LegoLogo from '../../shared/LegoLogo';
import Button from '../../shared/Button';
import EnterHighscoreImg from '../../assets/i/enter-your-name.png';
import badWords from './bad-words';

const lettersCount = 3;

export default function EnterHighscore({
  onEnter,
  onInput,
}) {
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (badWords.includes(name.toLocaleLowerCase())) {
      setError('No bad words please');
    } else {
      if (name.length === lettersCount) {
        onEnter(name);
      }
    }
  }, [onEnter, name]);

  const setNameValue = useCallback((name) => {
    console.debug('Settings name value: %s', name)
    setName(name);
    setError(null);
  }, []);

  useEffect(() => {
    onInput();
  }, [name, onInput]);

  return (
    <div className="tutorial-swipe-wrapper h-full flex flex-col items-center">
      <LegoLogo />

      <img src={EnterHighscoreImg} alt="Enter highscore" className="w-52 mt-6 xsmall:mt-1" />

      <form onSubmit={onSubmit} noValidate>
        <div className="mt-6 flex">
          <HighscoreInputs
            length={lettersCount}
            onChange={setNameValue}
            inputClassNames={`flex justify-center items-center bg-white text-black text-center text-2xl w-14 h-16 mx-1 px-2 py-2 transition-all
              rounded-lg border-4 border-[#034A97] focus:outline-none focus:border-[#0457af] font-ww-bold-italic`}
            inputFocusClassNames="outline-none border-[#006bdd]"
            inputStyles={{
              'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25), inset 10px 8px 4px rgba(0, 0, 0, 0.05)',
            }}
          />
        </div>

        {error && <p className="text-red-500 font-molot-bold-italic my-1 text-center">{error}</p>}

        <section className="flex flex-row justify-center mt-4">
          <Button type="submit" enterKeyHint="send">CONTINUE</Button>
        </section>

      </form>
    </div>
  )
}
