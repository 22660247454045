import Logo from '../../assets/i/logo.png';

export default function Layout({
  showFooter = true,
  children,
}) {
  return (
    <div className="layout">
      {children}

      {showFooter && (
        <footer className="w-full flex flex-col items-center mt-2 mb-1 fixed bottom-0 xsmall:opacity-80">
          <img src={Logo} alt="Logo" className="w-28 xsmall:w-14 relative z-10 top-0 active:top-1 transition-all duration-75" />
          <span className="text-white text-[9px] xsmall:text-[5px] bg-slate-600/30
            px-4 xsmall:px-2 py-1 xsmall:py-0.5 mt-0 rounded-full backdrop-blur-[1px]">
            © 2022 The LEGO Group. All rights reserved.
          </span>
        </footer>
      )}
    </div>
  )
}