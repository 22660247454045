import { Routes, Route, Navigate} from 'react-router-dom';
import Layout from '../shared/Layout';
import Home from './Home'
import Player from './Player'
import './App.css';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/play/:kioskId-:code" element={<Player />} />
        <Route path="*" element={<Navigate to="/" replace={false} />} />
      </Routes>
    </Layout>
  );
}

export default App;