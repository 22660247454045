import React, { useState } from 'react';
import { useSpringCarousel } from 'react-spring-carousel'
import LegoLogo from "../../shared/LegoLogo";
import ChooseCourse from '../../assets/i/choose-course.png';
import Course1 from '../../assets/i/course/course-1.png';
import Course2 from '../../assets/i/course/course-2.png';
import Course3 from '../../assets/i/course/course-3.png';
import PrevButtonIcon from '../../assets/i/arrow-prev.png';
import NextButtonIcon from '../../assets/i/arrow-next.png';
import Button from '../../shared/Button';

const carouselItems = [
  {
    id: 'course-1',
    renderItem: (
      <React.Fragment>
        <div className="course-image-wrapper">
          <img src={Course1} alt="Course 1" />
        </div>
      </React.Fragment>
    )
  },
  {
    id: 'course-2',
    renderItem: (
      <React.Fragment>
        <div className="course-image-wrapper">
          <img src={Course2} alt="Course 2" />
        </div>
      </React.Fragment>
    )
  },
  {
    id: 'course-3',
    renderItem: (
      <React.Fragment>
        <div className="course-image-wrapper">
          <img src={Course3} alt="Course 3" className="!max-h-[120%]" />
        </div>
      </React.Fragment>
    )
  },
];

export default function Course({
  onSelect,
  onSelection,
}) {
  const [course, setCourse] = useState(1);

  const {
    carouselFragment,
    useListenToCustomEvent,
    slideToPrevItem,
    slideToNextItem,
  } = useSpringCarousel({
    withLoop: true,
    springConfig: {
      mass: 1.1, tension: 140, friction: 15,
    },
    items: carouselItems,
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === 'onSlideStartChange') {
      const newIndex = event.nextItem.index + 1
      onSelection(newIndex);
      setCourse(newIndex);
    }

    if (event.eventName === 'onSlideChange') {
      const newIndex = event.currentItem.index + 1
      setCourse(newIndex);
      onSelection(newIndex);
    }
  })

  return (
    <div className="settings-course-wrapper h-full flex flex-col">
      <LegoLogo />

      <div className="flex flex-col grow">
        <div className="w-full text-center relative z-30">
          <img src={ChooseCourse} alt="Choose course" className="inline w-40" />
        </div>

        <div className="course-carousel-wrapper relative z-20 mt-2">
          {carouselFragment}

          <button className="direction-button prev" onClick={slideToPrevItem}>
            <img src={PrevButtonIcon} alt="Previous course" />
          </button>

          <button className="direction-button next" onClick={slideToNextItem}>
            <img src={NextButtonIcon} alt="Next course" />
          </button>
        </div>

        <section className="flex flex-row justify-center">
          <Button onClick={() => onSelect(course)}>CONTINUE</Button>
        </section>
      </div>
    </div>
  )
}
