import legoLogo from '../../assets/i/lego-logo.png';

export default function LegoLogo() {
  return (
    <div className="flex justify-center py-4">
      <img src={legoLogo} className="relative top-0 active:top-1 transition-all duration-75 w-28"
        alt="Lego logo" />
    </div>
  )
}
