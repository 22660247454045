import { useEffect, useMemo, useState } from "react";
import { useSwipeable } from 'react-swipeable';
import LegoLogo from '../../shared/LegoLogo';
import SwipeImg from '../../assets/i/swipe.png';
import SwipeLabel from '../../assets/i/swipe-label.png';
import Player1 from '../../assets/i/player/player-1.png';
import Player2 from '../../assets/i/player/player-2.png';
import Player3 from '../../assets/i/player/player-3.png';

export default function SetupSwipe({
  player,
  swipesRequired = 3,
  onSwipe,
  onSwipesCompleted,
}) {
  const [swipesCount, setSwipesCount] = useState(0);

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      console.debug('User swiped right');
      setSwipesCount((count) => count + 1);
      onSwipe();
    },
    delta: 70,
    swipeDuration: 900,
  });

  useEffect(() => {
    if (swipesCount >= swipesRequired) {
      onSwipesCompleted();
    }
  }, [swipesCount, onSwipesCompleted, swipesRequired]);

  const playerImg = useMemo(() => {
    switch (player) {
      case 1:
      default:
        return Player1;
      case 2:
        return Player2;
      case 3:
        return Player3;
    }
  }, [player]);

  return (
    <div className="tutorial-swipe-wrapper h-full flex flex-col items-center" {...handlers}>
      <LegoLogo />

      <div className="flex flex-col justify-center self-start w-full mt-4 bg-left-bottom bg-no-repeat
        bg-[length:85%] small:bg-[length:75%] xsmall:bg-[length:65%] max-w-[500px]" style={{
        backgroundImage: `url(${SwipeImg})`,
      }}>
        <img src={playerImg} alt="Player" className="block relative z-20 mb-3 max-h-min
          max-w-[80%] small:max-w-[70%] xsmall:max-w-[60%] animate-[swipe-player-in_ease-in_forwards_.2s]" />
      </div>

      <img src={SwipeLabel} alt="Swipe three times" className="max-w-[11rem] small:w-[9rem] xsmall:w-[7rem]
        mb-48 relative z-20 mt-4" />
    </div>
  )
}
