import Player from "./Player"
import Course from "./Course"


export default function Settings({
  type,
  onSelect,
  onSelection,
}) {
  if (type === 'course') {
    return <Course onSelect={onSelect} onSelection={onSelection} />
  } else if (type === 'player') {
    return <Player onSelect={onSelect} onSelection={onSelection} />
  }

  return null
}
