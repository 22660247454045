import React, { useState } from 'react';
import {
  useSpringCarousel,
  useTransitionCarousel,
} from 'react-spring-carousel'
import LegoLogo from "../../shared/LegoLogo";
import ChoorsePlayer from '../../assets/i/choose-player.png';
import Player1 from '../../assets/i/player/player-1.png';
import Player1Label from '../../assets/i/player/player-1-label.png';
import Player2 from '../../assets/i/player/player-2.png';
import Player2Label from '../../assets/i/player/player-2-label.png';
import Player3 from '../../assets/i/player/player-3.png';
import Player3Label from '../../assets/i/player/player-3-label.png';
import PrevButtonIcon from '../../assets/i/arrow-prev.png';
import NextButtonIcon from '../../assets/i/arrow-next.png';
import Button from '../../shared/Button';

const carouselItems = [
  {
    id: 'player-1',
    renderItem: (
      <div className="player-image-wrapper">
        <img src={Player1} alt="Player 1" />
      </div>
    )
  },
  {
    id: 'player-2',
  renderItem: (
      <div className="player-image-wrapper">
        <img src={Player2} alt="Player 2" />
      </div>
    )
  },
  {
    id: 'player-3',
    renderItem: (
      <div className="player-image-wrapper">
        <img src={Player3} alt="Player 3" />
      </div>
    )
  },
];

const carouselLabelItems = [
  {
    id: 'label-1',
    renderItem: (
      <div className="player-label-image-wrapper">
        <img src={Player1Label} alt="Player 1" className="max-w-[8rem]" />
      </div>
    )
  },
  {
    id: 'label-2',
    renderItem: (
      <div className="player-label-image-wrapper">
        <img src={Player2Label} alt="Player 2" />
      </div>
    )
  },
  {
    id: 'label-3',
    renderItem: (
      <div className="player-label-image-wrapper">
        <img src={Player3Label} alt="Player 3" />
      </div>
    )
  },
]

export default function Player({
  onSelect,
  onSelection,
}) {
  const [player, setPlayer] = useState(1);

  const {
    carouselFragment,
    useListenToCustomEvent,
    slideToPrevItem,
    slideToNextItem,
  } = useSpringCarousel({
    withLoop: true,
    springConfig: {
      mass: 1.1, tension: 140, friction: 15,
    },
    items: carouselItems,
  });

  const {
    carouselFragment: labelsCarouselFragment,
    slideToPrevItem: slideToPrevLabelItem,
    slideToNextItem: slideToNextLabelItem,
  } = useTransitionCarousel({
    items: carouselLabelItems,
    disableGestures: true,
    withLoop: true,
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === 'onSlideStartChange') {
      if (event.slideActionType === 'next') {
        slideToNextLabelItem();
      } else {
        slideToPrevLabelItem();
      }

      const newIndex = event.nextItem.index + 1
      onSelection(newIndex)
      setPlayer(newIndex);
    }

    if (event.eventName === 'onSlideChange') {
      const newIndex = event.currentItem.index + 1;
      setPlayer(newIndex);
      onSelection(newIndex);
    }
  })

  return (
    <div className="settings-player-wrapper h-full flex flex-col">
      <LegoLogo />

      <div className="flex flex-col grow">
        <div className="w-full text-center relative z-30">
          <img src={ChoorsePlayer} alt="Choose player" className="inline w-40" />
        </div>

        <div className="player-carousel-wrapper relative z-20 mt-2">
          {carouselFragment}

          <div className="w-full h-20 absolute -bottom-2">
            {labelsCarouselFragment}
          </div>

          <button className="direction-button prev" onClick={slideToPrevItem}>
            <img src={PrevButtonIcon} alt="Previous player" />
          </button>

          <button className="direction-button next" onClick={slideToNextItem}>
            <img src={NextButtonIcon} alt="Next player" />
          </button>
        </div>

        <section className="flex flex-row justify-center">
          <Button onClick={() => onSelect(player)}>CONTINUE</Button>
        </section>
      </div>
    </div>
  )
}
