import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Plausible from 'plausible-tracker'
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import './index.css';
import App from './components/App';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: "https://2a916cf7ed0d4e8bbb1866570d915af6@o1413651.ingest.sentry.io/6754375",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

window.trackQRScan = (kioskId) => {}
// window.trackUserSettings = (kioskId, course, player) => {}
window.trackGameCompletion = (kioskId) => {}

if (process.env.NODE_ENV === 'production') {
  const { enableAutoPageviews, trackEvent } = Plausible({
    domain: process.env.REACT_APP_PLAUSIBLE_DOMAIN,
  })

  enableAutoPageviews()

  window.trackQRScan = (kioskId) => {
    trackEvent('qr-scan', { props: { kioskId } });
  }
  
  // window.trackUserSettings = (kioskId, course, player) => {
  //   trackEvent('game-settings', { props: { kioskId, course, player } });
  // }
  
  window.trackGameCompletion = (kioskId) => {
    trackEvent('game-completed', { props: { kioskId } });
  }
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
