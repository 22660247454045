const list = [
  'anus',
  '@nus',
  'arse',
  '@rse',
  'ars3',
  'clit',
  'cl1t',
  'cock',
  'c0ck',
  'crap',
  'cunt',
  'dick',
  'd1ck',
  'fuck',
  'hell',
  'hel1',
  'he1l',
  'homo',
  'h0mo',
  'hom0',
  'jizz',
  'kunt',
  'nazi',
  'n@zi',
  'naz1',
  'n@z1',
  'piss',
  'p1ss',
  'shit',
  'sh1t',
  'slut',
  'tard',
  'tits',
  't1ts',
  'twat',
  'turd',
  'wank',

  'sex',
  'ass',
  'ass',
  'cum',
  'fag',
  'gay',
  'god',
  'jew',
  'tit',
  'kkk',
];

export default list