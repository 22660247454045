import Button from '../../shared/Button';
import Tutorial from '../../assets/i/tutorial.png';
import LegoLogo from '../../shared/LegoLogo';
import SetYourSpeed from '../../assets/i/set-your-speed.png';
import Speedometer from '../../shared/Speedometer';

export default function Speed({
  onSelect,
  onSkip,
}) {
  return (
    <div className="tutorial-jump-wrapper h-full flex flex-col items-center">
      <LegoLogo />

      <img src={Tutorial} alt="Tutorial" className="w-44" />

      <Speedometer onSelect={() => onSelect()} />

      <img src={SetYourSpeed} alt="Press the button" className="max-w-[11rem] small:w-[9rem] xsmall:w-[7rem]
        mb-4" />

      <section className="flex flex-row justify-center">
        <Button onClick={() => onSelect()}>CONTINUE</Button>
      </section>
    </div>
  )
}