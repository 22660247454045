import { useEffect, useState, useCallback } from 'react';
import socketIOClient from 'socket.io-client';

const useSocket = (serverUrl, options = {}) => {
  const [socket, setSocket] = useState();
  const [error, setError] = useState();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const socketInstance = socketIOClient(serverUrl, options);

    socketInstance.on('connect_error', () => {
      setError('WS connect error.');
    });

    socketInstance.on('connect', () => {
      setConnected(true);
      setError(undefined);
    });

    setSocket(socketInstance);

    return () => socketInstance.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { socket, connected, error }
}

function useSocketEvent(socket, event) {
  const [message, setMessage] = useState();

  useEffect(() => {
    // waiting for a socket to be available
    if (socket === undefined || !event) {
      return;
    }

    socket.on(event, (message) => {
      console.debug(`⇉ WS event ${event}`, message);
      setMessage(message);
    });
  }, [socket, event]);

  const emit = useCallback((...payload) => {
    socket.emit(event, ...payload)
  }, [socket, event]);

  return { message, emit };
}

export { useSocket, useSocketEvent };