import Tutorial from '../../assets/i/tutorial.png';
import ActionButton from '../../shared/ActionButton';
import Button from '../../shared/Button';
import LegoLogo from '../../shared/LegoLogo';
import PressTheButton from '../../assets/i/press-the-button.png';

export default function Jump({
  onSelect,
  onSkip,
}) {
  return (
    <div className="tutorial-jump-wrapper h-full flex flex-col items-center">
      <LegoLogo />

      <img src={Tutorial} alt="Tutorial" className="w-44" />

      <ActionButton onClick={onSelect} className="my-2" />

      <img src={PressTheButton} alt="Press the button" className="max-w-[11rem] small:max-w-[9rem] xsmall:max-w-[7rem]
        relative z-20" />

      <Button onClick={onSkip} className="absolute bottom-0 left-0 right-0 mb-4 mt-2 uppercase
        small:text-md xsmall:text-sm">
          Skip Tutorial
      </Button>
    </div>
  )
}