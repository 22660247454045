import { useEffect, useState, useRef } from 'react';
import useDimensions from '../../hooks/useDimensions';
import Scale from '../../assets/i/speedometer/scale.png';
import Dot from '../../assets/i/speedometer/dot.png'
import Pointer from '../../assets/i/speedometer/pointer.png'

const animationDurationMs = 3000;

const animationIntervalMs = 5;
const oneDegreeInRad = Math.PI / 180;

export default function Speedometer({
  ...props
}) {
  const canvasRef = useRef();
  const canvasDimensions = useDimensions(canvasRef);
  const dotRef = useRef();
  const dotDimensions = useDimensions(dotRef);
  const pointerRef = useRef();
  const [angle, setAngle] = useState(0);
  const [direction, setDirection] = useState('forward');

  useEffect(() => {
    let t = performance.now();
    let interval = setInterval(() => {
      const now = performance.now();
      const diff = now - t;
      t = now;
      const angleStep = diff / animationDurationMs * 180;

      setAngle((v) => v + (direction === 'forward' ? angleStep : -angleStep));
    }, animationIntervalMs);
    return () => clearInterval(interval);
  }, [direction]);

  useEffect(() => {
    if (angle <= 0) {
      setDirection('forward')
    } else if (angle >= 180) {
      setDirection('backward');
    }
  }, [angle]);

  useEffect(() => {
    if (!dotRef.current || !pointerRef.current || !canvasDimensions?.width || !dotDimensions?.width) {
      return;
    }

    const trackRadius = canvasDimensions.width / 2 - dotDimensions.width / 2
    const windowCenterX = trackRadius

    // get the point on the circle
    const circleX = Math.cos((angle + 180) * oneDegreeInRad) * trackRadius
       + windowCenterX
    const circleY = Math.sin((angle + 180) * oneDegreeInRad) * (trackRadius - dotDimensions.width / 4)

    dotRef.current.style.transform = `translate(${circleX}px, ${circleY}px)`;
    pointerRef.current.style.transform = `rotate(${angle - 90}deg)`;
  }, [dotRef, angle, canvasDimensions, dotDimensions, pointerRef ]);

  return (
    <div className={`${props.className || ''} max-w-[90%] mt-8`}>
      <div className="relative flex justify-center" ref={canvasRef}>
        <img src={Scale} alt="Speedometer" />

        <img src={Dot} alt=""
          className="absolute left-0 bottom-0 w-[33px] h-[32.5px] origin-[20%_90%]"
          ref={dotRef} />

        <img src={Pointer} alt="pointer" className="absolute z-20 bottom-[10%] w-[36px]
          origin-[50%_65%]" ref={pointerRef} />
      </div>
    </div>
  )
}
