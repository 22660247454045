import Jump from "./Jump";
import Speed from "./Speed";
import Swipe from "./Swipe";

function Tutorial({
  type,
  player,
  onSelect,
  onSkip,
  onSwipe,
}) {
  if (type === 'jump') {
    return <Jump onSelect={onSelect} onSkip={onSkip} player={player} />
  } else if (type === 'speed') {
    return <Speed onSelect={onSelect} onSkip={onSkip} player={player} />
  } else if (type === 'swipe') {
    return <Swipe onSwipesCompleted={onSelect} onSwipe={onSwipe} onSkip={onSkip} player={player} />
  }

  return null;
}

export default Tutorial