import Button from '../../shared/Button';
import LegoLogo from '../../shared/LegoLogo';
import SetYourSpeed from '../../assets/i/set-your-speed.png';
import Speedometer from '../../shared/Speedometer';

export default function SetupSetSpeed({
  onSet,
  onSkip,
}) {
  return (
    <div className="tutorial-jump-wrapper h-full flex flex-col items-center">
      <LegoLogo />

      <Speedometer className="mt-8" />

      <img src={SetYourSpeed} alt="Press the button" className="max-w-[13rem] small:w-[11rem] xsmall:w-[9rem]
        mb-4" />

      <section className="flex flex-row justify-center">
        <Button onClick={() => onSet()}>CONTINUE</Button>
      </section>
    </div>
  )
}