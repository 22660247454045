import React from 'react';
import LegoLogo from '../../shared/LegoLogo';
import Screensaver from '../../assets/i/screensaver.png';

export default function Home() {
  return (
    <div className="screensaver-wrapper">
      <LegoLogo />

      <div className="flex justify-center mt-12">
        <img src={Screensaver} alt="Scan to start!" className="max-w-[90%]" />
      </div>
    </div>
  )
}
