import { useState, useEffect, useMemo, useCallback, createRef } from 'react';

const KEY_CODE = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  DELETE: 46,
};

export default function HighscoreInputs({
  autoFocus = false,
  allowedCharsPattern = '^[a-zA-Z0-9_!@#$%^&*()-]$',
  length,
  onChange,
  inputClassNames = '',
  inputStyles = {},
  inputFocusClassNames = '',
}) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [value, setValue] = useState(new Array(length).fill(''));

  const allowedCharsRegex = useMemo(() => new RegExp(allowedCharsPattern), [allowedCharsPattern]);

  const codeInputRef = createRef();
  const itemsRef = useMemo(
    () =>
      new Array(length).fill(null).map(() => createRef()),
    [length]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getItem = useCallback((index) => itemsRef[index]?.current, []);
  const blurItem = (index) => getItem(index)?.blur();

  const onItemFocus = useCallback((index) => () => {
    setActiveIndex(index);
    if (codeInputRef.current) codeInputRef.current.focus();
  }, [codeInputRef]);

  const onInputKeyUp = useCallback(({ keyCode }) => {
    const newValue = [...value];
    const nextIndex = activeIndex + 1;
    const prevIndex = activeIndex - 1;

    const codeInput = codeInputRef.current;
    const currentItem = getItem(activeIndex);

    const isLast = nextIndex === length;
    const isDeleting =
      keyCode === KEY_CODE.DELETE || keyCode === KEY_CODE.BACKSPACE;

    // keep items focus in sync
    onItemFocus(activeIndex);

    // on delete, replace the current value
    // and focus on the previous item
    if (isDeleting) {
      newValue[activeIndex] = '';
      setValue(newValue);

      if (activeIndex > 0) {
        setActiveIndex(prevIndex);
      }
    }

    const key = codeInputRef.current.value;

    if (allowedCharsRegex.test(key)) {
      // reset the current value
      // and set the new one
      if (codeInput) codeInput.value = '';
      newValue[activeIndex] = key;
      setValue(newValue);

      if (!isLast) {
        setActiveIndex(nextIndex);
        return;
      }

      if (codeInput) codeInput.blur();
      if (currentItem) currentItem.blur();

      setActiveIndex(-1);
    } else {
      if (codeInput) codeInput.value = '';
    }
  }, [value, activeIndex, codeInputRef, getItem, length, onItemFocus, allowedCharsRegex]);

  const onInputBlur = () => {
    if (activeIndex === -1) return;

    blurItem(activeIndex);
    setActiveIndex(-1);
  };

  // autoFocus
  useEffect(() => {
    if (autoFocus && itemsRef[0].current) {
      itemsRef[0].current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const stringValue = value.join('');
    onChange(stringValue);
  }, [value, onChange]);

  return (
    <>
      <input
        type='text'
        ref={codeInputRef}
        autoComplete='off'
        onKeyUp={onInputKeyUp}
        onBlur={onInputBlur}
        style={{
          position: 'absolute',
          top: '-999px',
          opacity: 0,
        }}
      />

      {itemsRef.map((ref, i) => (
        <div
          className={`${inputClassNames} ${
            i === activeIndex
              ? inputFocusClassNames
              : ''
          }`}
          style={inputStyles}
          key={i}
          ref={ref}
          role='button'
          tabIndex={0}
          onFocus={onItemFocus(i)}
        >
          {value[i]}
        </div>
      ))}
    </>
  )

}