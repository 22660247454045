import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useDimensions(ref) {
  const [dimensions, setDimensions] = useState();

  useEffect(() => {
    if (ref.current) {
      const node = ref.current;

      setDimensions(node.getBoundingClientRect().toJSON());

      const resizeObserver = new ResizeObserver((entries) => {
        if (entries.length) {
          setDimensions(entries[0].target.getBoundingClientRect().toJSON());
        }
      });

      resizeObserver.observe(node);

      return () => {
        resizeObserver.unobserve(node);
        resizeObserver.disconnect();
      };
    }
  }, [ref]);

  return dimensions;
}